import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Figure from "../components/Figure"
import Section from "../components/Section"
import { H1, P } from "../components/VerticalRhythm"
import breakpoints from "../theme/breakpoints"

import PictureSet from "../components/PictureSet"
import imgObj19 from "../imgObjs/imgObj19"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nicht gefunden" />
    <Figure id={"master"} caption="">
      <PictureSet imgObj={imgObj19} />{" "}
    </Figure>
    <Section>
      <H1>Seite nicht gefunden</H1>
      <P>
        Die Seite, die Sie angegeben haben, wurde leider nicht gefunden. Bitte
        gehen Sie zurück auf die <Link to={"/"}>Startseite</Link> und schauen
        sich dort um. Vielen Dank.
      </P>
    </Section>
  </Layout>
)

export default NotFoundPage
